.footer{
    background: black;
    padding: 40px;
    .footer-logo{
        max-height: 30px;
    }
    .copyright-information{
        text-align: right;
        color: white;

        @include at-breakpoint(0,$m){
            text-align: center;
            font-size: smaller;
            margin-top: 20px;
        }
    }
}