.row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    --gap: 5px;
    --gap-s: var(--gap);
    --gap-m: var(--gap-s);
    --gap-l: var(--gap-m);

    gap: var(--gap);
    @include at-breakpoint($s){
        gap: var(--gap-s);
    }
    @include at-breakpoint($m){
        gap: var(--gap-m);
    }
    @include at-breakpoint($l){
        gap: var(--gap-l);
    }

    &.align-items-{
        &start{
            align-items: flex-start;
        }
        &center{
            align-items: center;
        }
        &end{
            align-items: flex-end;
        }
    }

    &.align-content-{
        &start{
            align-content: flex-start;
        }
        &center{
            align-content: center;
        }
        &end{
            align-content: flex-end;
        }
    }

    &.justify-content-{
        &start{
            justify-content: flex-start;
        }
        &center{
            justify-content: center;
        }
        &end{
            justify-content: flex-end;
        }
        &space-between{
            justify-content: space-between;
        }
        &space-around{
            justify-content: space-around;
        }
    }

    @each $breakpoint_label, $size in $breakpoint_labels{  
        &.align-items-#{$breakpoint_label}-{
            @include at-breakpoint($size){
                &start{
                    align-items: flex-start;
                }
                &center{
                    align-items: center;
                }
                &end{
                    align-items: flex-end;
                }
            }
            
        }
    
        &.align-content-#{$breakpoint_label}-{
            @include at-breakpoint($size){
                &start{
                    align-content: flex-start;
                }
                &center{
                    align-content: center;
                }
                &end{
                    align-content: flex-end;
                }
            }
            
        }
    
        &.justify-content-#{$breakpoint_label}-{
            @include at-breakpoint($size){
                &start{
                    justify-content: flex-start;
                }
                &center{
                    justify-content: center;
                }
                &end{
                    justify-content: flex-end;
                }
                &space-between{
                    justify-content: space-between;
                }
                &space-around{
                    justify-content: space-around;
                }
            }
            
        }
    }

    @for $i from 1 through 12 {
        .col-#{$i} {
            flex: 0 0 calc((100% / 12 * $i) - calc(var(--gap) * 2));
            max-width: calc((100% / 12 * $i) - calc(var(--gap) * 2));
        }
    }

    @each $breakpoint_label, $size in $breakpoint_labels{  
        @for $i from 1 through 12 {
            .col-#{$breakpoint_label}-#{$i} {
                @include at-breakpoint($size){
                    flex: 0 0 calc((100% / 12 * $i) - calc(var(--gap) * 2));
                    max-width: calc((100% / 12 * $i) - calc(var(--gap) * 2));
                }
            }
        }

        .col-#{$breakpoint_label}-5th{
            @include at-breakpoint($size){
                flex: 0 0 20%;
                max-width: 20%;
            }
        }

        .col-#{$breakpoint_label}-fluid{
            @include at-breakpoint($size){
                flex: 1;
                max-width: unset;
            }
        }
    }

    .col-fluid{
        flex: 1;
    }

    .col-5th{
        flex: 0 0 20%;
        max-width: 20%;
    }

    &.column{
        flex-direction: column;
    }

    &.full-height{
        height: 100%;
    }

    &.no-wrap{
        flex-wrap: nowrap;
    }
}