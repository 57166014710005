*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html, body{
    font-size: 18px;
    font-family: $body_font;
    background: $body_color;
    color: $text_color;
}

#personalizit{
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #15161C;
    padding: 20px;
    border-radius: 10px;
    .main-sidebar{
        flex: 0 0 20%;
        padding-right: 20px;
    }
    .main-container{
        flex: 1;   
        overflow-x: hidden;
        background: white;
        border-radius: 10px;
    }
}

.container{
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 20px;
}

h1,h2,.heading-font{
    font-family: $heading_font;
}

h1,h2,h3,h4,h5{
    font-weight: 500;
}

h1{
    font-size: 22px;
}

h2{
    font-size: 18px;
}

h3{
    font-size: 16px;
}

h4{
    font-size: 14px;
}

img{
    width: 100%;
    display: block;
    height: auto;
    object-fit: contain;
}

a{
    text-decoration: none;
    color: inherit;
}

button,.btn{
    color: white;
    padding: 10px 20px;
    display: block;
    text-align: center;
    font-weight: bold;
    width: fit-content;
    transition: .4s all ease-in-out;
    background-color: $text-color;
    border: none;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    &:hover{
        background-color: lighten($text-color, 10%);
    }
    svg{
        margin-left: 10px;
    }
}

hr{
    border: none;
    height: 2px;
    margin: 10px 0;
    width: 100%;
    background: rgba(0,0,0,0.1);
}

.or{
    margin: 10px 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 20px;
    hr{
        flex: 1;
    }
}

.tagline{
    text-transform: uppercase;
    letter-spacing: 2px;
    &:has(svg){
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.field{
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 0;
    border: 1px solid rgba(0,0,0,1);
    background: white;
    transition: .4s all ease-in-out;
    label{
        position: absolute;
        font-size: 75%;
        top: 4px;
        left: 4px;
        opacity: .7;
        pointer-events: none;
        user-select: none;
        display: block;
    }
    input, select, textarea{
        padding: 20px 5px 5px 5px;
        width: 100%;
        border-radius: 0;
        border: none;
        font-size: 15px;
        &:focus{
            outline: none;
        }
    }
    &:has(*:focus){
        border: 1px solid var(--contrast-color);
    }
    &:has(*[required]:invalid){
        &:after{
            content: 'Required';
            position: absolute;
            bottom: 4px;
            right: 4px;
            font-size: 75%;
            opacity: .4;
            pointer-events: none;
            user-select: none;
        }
    }
}