.test{
    padding: 40px 0;
    .test-description{
        padding: 20px;
        border-radius: 10px;
        h1{
            font-size: 42px;
            font-weight: bold;
        }
        p{
            margin-top: 20px;
        }

        @include at-breakpoint($m){
        }
    }

    .btn{
        margin-left: auto;
        @include at-breakpoint(0,$m){
            width: 100%;
        }
    }


    @include at-breakpoint($m){
        padding: 40px;
    }
    
    img{
        margin-top: 50px;
    }

    
}

.test-1{
    background-image: linear-gradient(to bottom right, var(--accent-color), var(--accent-color-dark-2));
}

.test-2{
    background-image: linear-gradient(to bottom right, var(--tertiary-color), var(--tertiary-color-dark-2));
}

