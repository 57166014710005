.main-header{
    padding: 10px 0;
    .logo-container{
        img{
            max-width: 140px;
            padding: 20px;
            filter: invert(1);
        }
    }

    .navigation{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
}