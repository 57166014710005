.brand-list{
    text-align: center;
    padding-bottom: 100px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    > *{
        text-align: center;
    }

    h1{
        font-size: 52px;
        text-transform: capitalize;
        font-weight: bold;
        @include at-breakpoint(0,$m){
            font-size: 36px;
        }
    }

    .brands{
        margin-top: 50px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 40px;

        img{
            max-width: 150px;
            max-height: 75px;
            &.white-logo{
                filter: grayscale(1) invert(1);
            }
        }
    }
}