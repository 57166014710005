$s:  0;
$m: 768px;
$l: 992px;
$xl: 1200px;
$xxl: 1440px;

$breakpoint_labels: (
    'sm': $s,
    'md': $m,
    'lg': $l,
    'xl': $xl,
    'xxl': $xxl
);

$grid_col_count: 12;

$heading_font: 'Work Sans', sans-serif;
$body_font: 'Work Sans', sans-serif;
