.job-types{
    padding: 100px 0;
    .job-type{
        text-align: center;
        background: var(--tertiary-color);
        padding: 30px;
        border-radius: 10px;
        h3{
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
        }
        h2{
            margin-bottom: 20px;
            font-size: 32px;
            font-weight: bold;
        }
        img{
            width: 80%;
            aspect-ratio: 1/1;
            margin: 40px auto;
        }
        .job-type-title{
            font-size: 18px;
            font-weight: 500;
        }
        .points{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 5px;
            .point{
                padding: 10px 20px;
                background: var(--tertiary-color-dark-6);
                border-radius: 10px;
                font-weight: bold;
                color: white;
                font-size: small;

            }
        }

        &:nth-child(2){
            background: var(--accent-color-light-1);
            .point{
                background: var(--accent-color-dark-3);
            }
        }
        &:nth-child(3){
            background: var(--contrast-color-light-5);
            .point{
                background: var(--contrast-color-light-1);
            }
        }
    }
}