.hero-section{
    .usps{
        font-size: 52px;
        @include at-breakpoint(0,$m){
            font-size: 36px;
        }
        text-transform: capitalize;
        h1{
            font-size: inherit;
            font-weight: bold;
            @include at-breakpoint(0,$m){
                br{
                    display: none;
                }
            }
        }
        .usp-claim {
            overflow: hidden;
            display: block;
            .usp-wrapper{
                display: flex;
                flex-wrap: nowrap;
                position: relative;
                left: calc(var(--active-usp) * -100%);
                .usp{
                    flex: 0 0 100%;
                    font-weight: bold;
                    color: var(--accent-color);
                    // overflow: hidden;
                    white-space: nowrap;
                    .tagline-text{
                        width: fit-content;
                        padding-right: 10px;
                        position: relative;
                        &:after{
                            content: '';
                            background: white;
                            position: absolute;
                            inset: 0 0 0 0;
                            transition: 1.4s left ease-in-out;
                            border-left: 2px solid black;
                            animation-name: ShowUsp;
                            animation-duration: var(--animation-duration);
                            animation-iteration-count: infinite;
                        }
                    }
                }
            }
        }
    }

    .images-container{
        @include at-breakpoint(0,$m){
            order: -1;
            // margin-bottom: 40px;
        }
    }
    .hero-images{
        position: relative;
        aspect-ratio: 3/2.5;
        .hero-image{
            position: absolute;
            inset: 0 0 0 0;
            aspect-ratio: inherit;
            object-fit: cover;
            border-radius: 20px;
            opacity: 0;
            transition: 1s all ease-in-out;
            &.active{
                opacity: 1;
                z-index: 2;
            }
        }
    }

    .introductory-paragraph{
        font-size: larger;
        @include at-breakpoint($m){
            margin: 50px 0;
        }
    }    

    hr{
        margin: 40px 0 ;
    }

    .stats{
        display: flex; 
        gap: 10px;
        margin-top: 30px;
        .stat{
            flex: 0 0 33%;
            strong{
                font-size: 36px;
                display: block;
            }
        }
    }
}

@keyframes ShowUsp{
    0%{
        left: 0%;
    }
    25%{
        left: 100%;
    }
    75%{
        left: 100%;
    }
    100%{
        left: 0%;
    }
}