.introduction{
    padding: 40px 0;
    text-align: center;
    h1{
        font-size: 42px;
        font-weight: bold;
        margin-bottom: 20px;
        span{
            color: var(--accent-color);
        }
    }
    p{
        margin-bottom: 20px;
    }
    button{
        margin: 0 auto;
        margin-top: 40px;
    }

    strong.highlight{
        margin-top: 20px;
    }
}