$body_color: #FFFFFF;
$text_color: #282828;

$accent: #65BD7D;
$contrast: #2698C0;
$tertiary: #FEE979;
$scaffold: #949597;

:root{
    @include color_shade_generator($accent, "accent");
    @include color_shade_generator($contrast, "contrast");
    @include color_shade_generator($tertiary, "tertiary");
    --scaffold-color: #{$scaffold};
}
